// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery-ui/ui/widgets/autocomplete")
require("jquery-ui/ui/widgets/draggable")

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
import "bootstrap";
import "../stylesheets/application";
import "../stylesheets/players_lounge";
import flatpickr from 'flatpickr';
require("flatpickr/dist/flatpickr.min.css")
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons/faLongArrowAltRight";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons/faEyeSlash";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons/faLongArrowAltLeft";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons/faMinusCircle";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons/faCaretRight";
import { faFireAlt } from "@fortawesome/free-solid-svg-icons/faFireAlt";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons/faPlayCircle";
import { faVideo } from "@fortawesome/free-solid-svg-icons/faVideo";
import { faCamera } from "@fortawesome/free-solid-svg-icons/faCamera";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons/faArrowDown";
import { faBolt } from "@fortawesome/free-solid-svg-icons/faBolt";
import { faCalendar } from "@fortawesome/free-solid-svg-icons/faCalendar";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import { faCalendarWeek } from "@fortawesome/free-solid-svg-icons/faCalendarWeek";
import { faCalendarTimes } from "@fortawesome/free-solid-svg-icons/faCalendarTimes";
import { faCalendarPlus } from "@fortawesome/free-solid-svg-icons/faCalendarPlus";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons/faCalendarCheck";
import { faChevronCircleDown } from "@fortawesome/free-solid-svg-icons/faChevronCircleDown";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faCircle } from "@fortawesome/free-solid-svg-icons/faCircle";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons/faSlidersH";
import { faTh } from "@fortawesome/free-solid-svg-icons/faTh";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare";
import { faTwitterSquare } from "@fortawesome/free-brands-svg-icons/faTwitterSquare";
import { faTiktok } from "@fortawesome/free-brands-svg-icons/faTiktok";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkedAlt";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons/faUserCircle";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons/faCreditCard";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import { faFileContract } from "@fortawesome/free-solid-svg-icons/faFileContract";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons/faQuoteRight";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons/faQuoteLeft";
library.add(faArrowRight,faLongArrowAltRight,faCheckCircle,faClock,faInfoCircle,faTimesCircle,faStar,faEye,faEyeSlash,faArrowLeft,faLongArrowAltLeft,faPlusCircle,faMinusCircle,faCaretDown,faCaretRight,faFireAlt,faPlayCircle,faVideo,faCamera,faSearch,faPhone,faChevronRight,faChevronLeft,faChevronUp, faChevronDown,faArrowUp,faArrowDown,faBolt,faCalendar,faCalendarAlt,faCalendarWeek,faCalendarTimes,faCalendarPlus,faCalendarCheck,faChevronCircleDown,faCheck,faCircle,faSlidersH,faTh,faTrash,faFacebookSquare,faTwitterSquare,faTiktok,faLinkedin,faInstagram,faMapMarkedAlt,faList,faUserCircle,faExclamationCircle,faCreditCard,faQuestionCircle,faFileContract,faQuoteLeft,faQuoteRight);
dom.watch();

$(document).on('click','input[type="submit"]', function() {
    $("#loading-overlay").show();
});

$(document).on('click','button[type="submit"]', function() {
    $("#loading-overlay").show();
});

$(document).on('click','a.availability-check', function() {
    $("#loading-overlay").show();
});

$(document).on('click','a.btn.map', function() {
    $("#loading-overlay").show();
});

$(document).on('turbolinks:load', function () {
    
    $("#product_nav").on("click", function(e){
        e.preventDefault();
        $(".product_nav").addClass("active");
        $(".sticky-footer").addClass("d-none").removeClass("d-block");
    });
        
    $("#close_product_nav").on("click", function(e){
        e.preventDefault();
        $(".product_nav").removeClass("active");
        $(".sticky-footer").addClass("d-block").removeClass("d-none");
    });

    $(".product_menu").on("mouseleave", function(e){
        $(".product_nav").removeClass("active");
    });

});

$(document).ready(function(){
  $("#loading-overlay").hide();
});

$(document).on('turbolinks:click', function() {
  $("#loading-overlay").show();
});

$(document).on('turbolinks:load', function() {
  $("#loading-overlay").hide();
  	$(".next_page").on('click', function(e){
  		e.preventDefault();
  		url = $(this).attr('href');
  		$.getScript(url);
		}
	);
});

$(document).on('turbolinks:load', function() {
  	$("#managed_services_content").on("click", "button.toggler", function(){
		$(".toggle").toggleClass("d-none");
	});
});


$(document).on('turbolinks:load', function() {
if (window.HubSpotConversations) 
    {
        window.HubSpotConversations.widget.remove();
    } 
});